<template>
  <div class="feature-item dark:bg-gray-700 dark:text-white">
    <div class="mt-2 xs:mt-0">
      <s-icon size="100" :color="color" :ico="ico"/>
    </div>
    <div class="ml-6">
      <div class="feature-item-title" :style="{color}">{{title}}</div>
      <div class="feature-item-desc">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import SIcon from "@/components/core/SIcon";
export default {
  name: "FeatureItem",
  props: ['color', 'title', 'desc', 'ico'],
  components: {SIcon}
}
</script>

<style scoped lang="scss">

.feature-item{
  @apply flex mt-8 mx-4 bg-white rounded-xl p-6 shadow-xl;
  .feature-item-title{
    @apply text-lg sm:text-2xl font-bold;
  }
  .feature-item-desc{
    @apply text-sm xs:text-base;
  }
}
</style>