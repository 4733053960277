<template>
  <s-card
      class="text-white cursor-pointer text-left"
      v-ripple
      :class="{
      'female-gradient': list.spectrum === 0,
      'neutral-gradient': list.spectrum === 0.5,
      'male-gradient': list.spectrum === 1,
      'all-gradient': ![0,0.5,1].includes(list.spectrum)
    }"
  >
    <div class="flex justify-between text-3xl">
      <div class="whitespace-nowrap mr-8 sm:mr-32">
        <div>{{list.name}}</div>
        <div class="text-sm">Created: {{list.created ? list.created.toLocaleDateString("en-US") : ''}}</div>
      </div>
      <div class="text-right">
        <div class="text-4xl">{{list.names.length}}</div>
        <div class="text-lg leading-4 text-gray-300">names</div>
      </div>
    </div>
    <div class="flex justify-end mt-2 text-gray-300 font-bold">
      <div class="flex text-2xl">
        Continue <s-icon ico="mdi-play" size="30"/>
      </div>

    </div>
  </s-card>
</template>

<script>
import SCard from "@/components/core/SCard";
import SIcon from "@/components/core/SIcon";
export default {
  name: "ListButton",
  components: {SIcon, SCard},
  props: {
    list: {default: ()=>({
        name: 'My List',
        names: [],
        spectrum: 0,
        created: new Date()
    })}
  }
}
</script>

<style scoped lang="scss">
  .edit-btn{
    @apply absolute rounded-full p-2 shadow-lg bg-gray-200 cursor-pointer;
    top: -1.25rem;
    left: -1.25rem;
  }
</style>