<template>
  <div class="magenta-svg w-72 py-4 text-2xl text-white">
    <div class="text-4xl font-bold mt-4 text-center">End The<br>Name Search</div>
    <div class="text-gray-300 mt-2 text-center text-lg">Find the perfect name</div>
    <div class="divider"></div>
    <div class="menu-item" v-ripple>
      <s-icon ico="mdi-compass" size="32" class="mr-4"/> Explore
    </div>
    <div class="menu-item" v-ripple>
      <s-icon ico="mdi-format-list-bulleted-square" size="32" class="mr-4"/> Lists
    </div>
    <div class="menu-item" v-ripple>
      <s-icon ico="mdi-account" size="32" class="mr-4"/> Account
    </div>
    <div class="menu-item" v-ripple>
      <s-icon ico="mdi-sign-out" size="32" class="mr-4"/> Sign Out
    </div>
  </div>
</template>

<script>
import SIcon from "@/components/core/SIcon";
export default {
name: "SideNav",
  components: {SIcon}
}
</script>

<style scoped lang="scss">
  .divider{
    @apply mt-6;
    border-top: 1px solid rgba(0,0,0,0.2);
    height: 1px;
  }
  .menu-item{
    @apply flex p-4;
  }
</style>