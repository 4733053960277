<template>
  <div>
    <!-- Splash Screen -->
    <splash-screen class="magenta-svg"/>

    <!-- Get Started -->
    <div class="text-center bg-white">
      <get-started/>
    </div>

    <!-- Features -->
    <div class="features-list">
      <div class="features-container">
        <div
          v-for="(feature,i) in features"
          :key="i"
        >
          <vue-aos :animation-class="getFadeIn(i)">
            <feature-item :title="feature.title" :color="feature.color" :ico="feature.ico">
              {{feature.desc}}
            </feature-item>
          </vue-aos>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <s-footer/>
  </div>
</template>

<script>
import FeatureItem from "@/views/Home/FeatureItem";
import GetStarted from "@/views/Home/GetStarted";
import SplashScreen from "@/views/Home/SplashScreen";
import SFooter from "@/components/core/SFooter";

export default {
  name: 'Home',
  components: {
    SFooter,
    SplashScreen,
    GetStarted,
    FeatureItem,
  },
  data: ()=>({
    features: [
      {color: '#D62828', ico: 'mdi-playlist-star', title: 'Build Your Lists', desc: 'Create a list to save your favorite names. End The Name Search makes it easy to organize the names that mean the most to you.'},
      {color: '#27ae60', ico: 'mdi-shuffle-variant', title: 'Shuffle Your Names', desc: 'When looking for our creativity, sponteneity lets us find ideas fast. By default, End The Name Search offers names in a ranom order.'},
      {color: '#2980b9', ico: 'mdi-account-star', title: 'Target Popularity', desc: 'It\'s easy to target the popular (or rare) names. Filter your search by popularity to get the most commonly used names or the rare ones!'},
      {color: '#8e44ad', ico: 'mdi-chart-line-variant', title: 'See The Charts', desc: 'View how a name\'s popularity changes over time. With helpful charts, you can avoid choosing a name that\'s going out of style.'},
    ]
  }),
  methods: {
    getFadeIn(i){
      return i % 2 ? 'fadeInRight animated' : 'fadeInLeft animated';
    }
  }
}
</script>

<style scoped lang="scss">
  .features-list{
    @apply bg-gray-100 dark:bg-gray-800;
    .features-container{
      @apply container max-w-6xl grid grid-cols-1 lg:grid-cols-2 mx-auto mx-6 pt-6 pb-16;
    }
  }
</style>
