<template>
  <div>
    <transition name="fade">
      <div
        v-if="show"
        class="dialog-bg s-dialog"
        :class="{centered: !side, right: side==='right', left: side==='left'}"
        @click="closeHandler"
      >
        <transition name="slide" appear>
          <slot/>
        </transition>

      </div>
    </transition>
  </div>

</template>

<script>
export default {
  name: "SDialog",
  props: {
    show: {default: false},
    side: {default: null}
  },
  methods: {
    closeHandler(target){
      if(target.target.classList.item(0)==='dialog-bg')
        this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
  .s-dialog{
    @apply fixed top-0 left-0 flex bg-darken-60 w-full h-full fixed z-30;
  }
  .centered{
    @apply flex-wrap justify-center content-center;
  }
  .right{@apply justify-end;}
  .left{@apply justify-start;}
</style>