<template>
  <s-card class="magenta-svg text-white px-4">
    <!-- Header -->
    <div class="flex justify-between">
      <div class="text-2xl font-bold ml-4">Sign In</div>
      <div
          @click="close"
          class="rounded-full h-10 w-10 p-2 bg-darken-20 cursor-pointer"
          v-ripple
      >
        <s-icon ico="mdi-close" size="24"/>
      </div>
    </div>

    <!-- Sign In -->
    <div class="flex flex-col">
      <div class="flex flex-col justify-center" v-if="useEmail">
        <!-- Email Form -->
        <div class="text-sm text-gray-300">Email Address</div>
        <input class="form-textbox" type="text">
        <div class="text-sm text-gray-300">Password</div>
        <input class="form-textbox" type="text">
        <div class="text-sm text-gray-300" v-if="join">Confirm Password</div>
        <input class="form-textbox" v-if="join" type="text">

        <button class="provider-btn mx-auto flex justify-center bg-darken-40" v-ripple>
          <div class="flex">
            <s-icon ico="mdi-alert-circle" size="18" class="mt-1 mr-1"/>
            {{join ? 'Sign Up' : 'Sign In'}}
          </div>
        </button>

        <!-- Errors -->
        <div class="text-red-300 leading-4 mt-4 bg-darken-50 p-2 rounded-lg" v-if="activeErrors.length > 0">
          <div
              class="flex"
              v-for="e in activeErrors"
              :key="e.err"
          >
            <s-icon ico="mdi-alert-circle" class="mr-1"/>
            {{e.text}}
          </div>
        </div>

        <div class="text-center text-sm mt-4">
          <div>Forget your password?  <a>Click to reset</a></div>
        </div>

        <div class="mt-6 text-center">{{join ? 'Already have an account?' : 'No account?'}}</div>
        <button class="sign-up-btn" v-ripple @click="join=!join">
          {{join ? 'Sign In' : 'Sign Up'}}
        </button>
      </div>

      <!-- Sign in with social media -->
      <div class="p-8 text-lg font-bold">
        <button v-if="useEmail" class="flex justify-center provider-btn bg-nephritis" v-ripple @click="useEmail=false">
          <s-icon ico="mdi-account-group" size="24" class="mr-2"/>
          <div>Use Social Media</div>
        </button>
        <div v-if="!useEmail">
          <button class="flex provider-btn bg-nephritis" v-ripple @click="useEmail=true">
            <s-icon ico="mdi-email" size="24" class="mr-2"/>
            <div>Continue With Email</div>
          </button>
          <button @click="signInWithGoogle" class="flex provider-btn" style="background-color: #DB4437" v-ripple>
            <s-icon ico="mdi-google" size="24" class="mr-2"/>
            <div>Continue With Google</div>
          </button>
          <button class="flex provider-btn" style="background-color: #4267B2" v-ripple>
            <s-icon ico="mdi-facebook" size="24" class="mr-2"/>
            <div>Continue With Facebook</div>
          </button>
          <button class="flex provider-btn" style="background-color: #1DA1F2" v-ripple>
            <s-icon ico="mdi-twitter" size="24" class="mr-2"/>
            <div>Continue With Twitter</div>
          </button>
        </div>

      </div>
    </div>
  </s-card>
</template>

<script>
import SCard from "@/components/core/SCard";
import SIcon from "@/components/core/SIcon";
import {mapActions} from "vuex";
export default {
  name: "SignInDialog",
  components: {SIcon, SCard},
  data: ()=>({
    join: false,
    useEmail: false,
    errors: [
      {err: 'credentials', text: 'Password and email do not match'},
      {err: 'invalid-email', text: 'Invalid email address'},
      {err: 'password-short', text: 'Password must be at least 6 characters'},
      {err: 'password-long', text: 'Password must be less than 25 characters'},
      {err: 'confirm-wrong', text: 'Passwords do not match'},
    ]
  }),
  methods: {
    ...mapActions('auth', ['signInWithGoogle']),
    close(){
      this.$store.commit('toggleShowSignIn', false);
    }
  },
  computed: {
    activeErrors(){
      let getErr = (err) => this.errors.find(o => o.err === err);
      let errs = [];
      errs.push(getErr('invalid-email'))
      return this.errors;
    },
  }
}
</script>

<style scoped lang="scss">
  .sign-in-text{
    @apply p-8 pt-16 w-80 text-center;
    border-right: 1px solid rgba(0,0,0,0.2)
  }
  .form-textbox{
    @apply py-2 px-4 bg-darken-20 rounded-lg w-full;
  }
  .provider-btn{
    @apply mt-2 whitespace-nowrap rounded-xl shadow-lg p-2 px-4 w-72;
  }
  .sign-up-btn{
    @apply rounded-xl w-full px-16 py-2 font-bold text-lg;
    @apply hover:text-white hover:shadow-xl hover:bg-darken-30;
    border: 2px solid rgba(255,255,255,0.5);
    color: white;
  }
</style>