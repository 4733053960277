<template>
  <div class="pb-12 dark:bg-gray-900 dark:text-white">
    <!-- If signed in -->
    <div v-if="signedIn">
      <div class="text-4xl pt-12 bg-white dark:bg-gray-900">Continue Your List</div>
      <list-button class="max-w-min mt-6 mb-2 mx-auto" :list="activeList"/>
      <div>View all my lists (2)</div>
    </div>

    <!-- Start a new list -->
    <div class="text-4xl pt-12 bg-white dark:bg-gray-900">
      {{signedIn ? 'Start a New List' : 'Get Started'}}
    </div>
    <div class="text-xl text-gray-500">
      Start a list and begin your search for the perfect name
    </div>
    <div class="flex justify-center">
      <div class="get-started">
        <div v-ripple class="list-button rounded-none rounded-tl-lg male-gradient">
          <div>Male</div>
        </div>
        <div v-ripple class="list-button rounded-tr-lg md:rounded-none neutral-gradient">
          <div>Neutral</div>
        </div>
        <div v-ripple class="list-button rounded-bl-lg md:rounded-none female-gradient">
          <div>Female</div>
        </div>
        <div v-ripple class="list-button rounded-br-lg md:rounded-none all-gradient">
          <div>All</div>
        </div>
      </div>
    </div>

    <!-- Join or sign in button -->
    <div v-if="!signedIn" class="flex flex-col md:flex-row justify-center mt-8">
      <div class="flex justify-center mb-6 md:mb-0">
        <div>
          <s-icon ico="mdi-account" size="64"/>
        </div>
        <div class="text-left mt-1 text-2xl">
          <div class="font-bold">Make an Account</div>
          <div class="text-xl">Join now to save your lists</div>
        </div>
      </div>

      <div class="ml-0 md:ml-16">
        <button @click="toggleShowSignIn" class="sign-in-button max-w-md" v-ripple>
          Sign In or Join
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import SIcon from "@/components/core/SIcon";
import {mapMutations, mapState, mapGetters} from "vuex";
import ListButton from "@/views/Lists/ListButton";
export default {
  name: "GetStarted",
  components: {ListButton, SIcon},
  computed: {
    ...mapState(['signedIn']),
    ...mapGetters('lists', ['activeList'])
  },
  methods: {
    ...mapMutations(['toggleShowSignIn'])
  }
}
</script>

<style scoped lang="scss">

// Get started
.get-started{
  @apply container grid grid-cols-2 md:grid-cols-4 max-w-4xl my-6 mx-4 shadow-lg rounded-lg;
  :first-child{
    @apply md:rounded-l-lg;
  }
  :last-child{
    @apply md:rounded-r-lg;
    border: none;
  }
  .list-button{
    @apply text-2xl md:border-r text-white cursor-pointer;
    border-right: 1px solid rgba(0,0,0,0.2);
    :first-child{@apply w-full h-full p-6;}
    :first-child:hover{
      background-color: rgba(0,0,0,0.2);
    }
  }
}

.sign-in-button{
  @apply rounded-xl px-16 py-4 font-bold text-3xl;
  @apply hover:text-white hover:shadow-xl;
  border: 4px solid rgb(172,12,97);
  color: rgb(172,12,97);
}
.sign-in-button:hover {
  background: rgb(172, 12, 97);
  background: linear-gradient(38deg, rgba(172, 12, 97, 1) 10%, rgba(122, 7, 57, 1) 100%);
}
</style>