<template>
  <div id="top-nav">
    <etns-logo-icon class="w-8 h-8 mt-3 ml-3"/>
    <div class="flex">
      <!-- Nav options -->
      <div
        class="nav-btn"
        v-ripple
        v-for="(link, i) in showOptions"
        :key="i"
        @click="$router.push(link.path)"
      >
        <s-icon :ico="link.ico" size="22" class="mt-1 pl-2" :class="{'mr-2': link.title || ['xs','sm'].includes($screen.breakpoint)}"/>
        <div
          class="caption pr-3"
          v-if="!['xs','sm'].includes($screen.breakpoint)"
        >
          {{link.title}}
        </div>
      </div>

      <!-- Account -->
      <div class="nav-btn relative" ref="accountMenuRef" @click="showAccountMenu=!showAccountMenu">
        <s-icon ico="mdi-account" size="22" class="mt-1 px-1"/>
        <transition name="fade">
          <div class="absolute right-0 top-8 w-60 bg-darken-50 z-10 rounded-lg" v-if="showAccountMenu">
            <div
                class="flex font-normal p-4 hover:bg-darken-20"
                v-ripple
                v-closable="{handler: 'closeAccountMenu', exclude: ['accountMenuRef']}"
            >
              <s-icon ico="mdi-account" size="26" class="mr-4"/>
              My Account
            </div>
            <div class="flex font-normal p-4 hover:bg-darken-20" @click="signOut" v-ripple>
              <s-icon ico="mdi-logout" size="26" class="mr-4"/>
              Sign Out
            </div>
          </div>
        </transition>
      </div>
      <div class="nav-btn" v-ripple v-if="!signedIn" @click="toggleShowSignIn">
        <s-icon ico="mdi-login" size="22" class="mr-2 mt-1 pl-2"/>
        <div class="caption pr-3">Sign In</div>
      </div>

      <!-- Hamburger menu -->
      <div @click="showMenu=true" class="nav-btn ml-3" v-if="$screen.breakpoint==='xs'" v-ripple>
        <s-icon ico="mdi-menu" size="22" class="mt-1"/>
      </div>
<!--      <div class="pr-4 text-right hover:bg-darken-20 pt-2 pl-4 cursor-pointer rounded-bl-lg whitespace-nowrap" v-ripple>-->
<!--        <div>My Awesome List</div>-->
<!--        <div class="text-lg font-bold">22 Names</div>-->
<!--      </div>-->
      <s-dialog :show="showMenu" @close="showMenu=false" side="right">
        <side-nav/>
      </s-dialog>
    </div>

  </div>
</template>

<script>
import SIcon from "@/components/core/SIcon";
import {mapState, mapMutations, mapActions} from "vuex";
import EtnsLogoIcon from "@/assets/etnsLogoIcon";
import SDialog from "@/components/core/SDialog";
import SideNav from "@/components/core/STopNav/SideNav";

export default {
  name: "STopNav",
  components: {SideNav, SDialog, EtnsLogoIcon, SIcon},
  data: ()=>({
    links: [
      {title: 'Explore', path: 'explore', ico: 'mdi-compass'},
      {title: 'Lists', path: 'lists', ico: 'mdi-format-list-bulleted-square'},
    ],
    showMenu: false,
    showAccountMenu: false
  }),
  computed: {
    ...mapState(['signedIn']),
    showOptions(){
      if(!this.signedIn || this.$screen.breakpoint === 'xs') return [];
      return this.links;
    }
  },
  methods: {
    ...mapMutations(['toggleDark', 'toggleShowSignIn']),
    ...mapActions('auth', ['signOut']),
    closeAccountMenu(){
      this.showAccountMenu = false;
    }
  }
}
</script>

<style scoped lang="scss">
  #top-nav{
    @apply flex justify-between w-full h-16;

    .nav-btn{
      @apply bg-darken-20 px-2 pt-1 mt-2 mb-4 rounded-xl cursor-pointer text-white mr-4 font-bold shadow-lg flex text-lg;
      @apply hover:bg-darken-40;
      .caption{
        margin-top: 2px;
      }
    }
  }
</style>