import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
// Vue addons
import VueAos from 'vue-aos'
import Ripple from 'vue-ripple-directive'
import VueScreen from 'vue-screen'
import VueClosable from 'vue-closable'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/material.css'
// Firebase
import firebase from "firebase/compat";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDmge2_d9HusnqmlRsnn7Eeo82CEy1yb-U",
  authDomain: "end-the-name-search.firebaseapp.com",
  databaseURL: "https://end-the-name-search.firebaseio.com",
  projectId: "end-the-name-search",
  storageBucket: "end-the-name-search.appspot.com",
  messagingSenderId: "963281532338",
  appId: "1:963281532338:web:7b6423fe4fe2b10694a0ab",
  measurementId: "G-2DY4SDKJ9B"
};

firebase.initializeApp(firebaseConfig);
// firebase.functions().useEmulator("192.168.4.12", 5001);

Vue.directive('ripple', Ripple);
Vue.component('VueSlider', VueSlider);
Vue.use(VueClosable);
Vue.use(VueAos);
Vue.use(VueScreen, 'tailwind');
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
