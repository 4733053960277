<template>
  <div class="footer">
    (c) 2021 endthenamesearch.com
  </div>
</template>

<script>
export default {
name: "SFooter"
}
</script>

<style scoped lang="scss">
.footer{
  @apply py-2 bg-gray-800 text-right text-gray-400 text-sm px-4;
}
</style>