<template>
  <s-card class="bg-splash-magenta-dark text-white w-80">
    <!-- Title -->
    <div class="flex justify-between mb-4">
      <div class="text-2xl mt-1 font-bold">New List</div>
      <div class="bg-darken-10 rounded-full p-2 hover:bg-darken-20 cursor-pointer" @click="toggleShowEditList" v-ripple>
        <s-icon ico="mdi-close" size="24"/>
      </div>
    </div>

    <!-- Form -->
    <div>
      <!-- List Name -->
      <div>
        <div class="text-sm text-gray-300">List Name</div>
        <input class="form-textbox" type="text" placeholder="My New List">
      </div>
      <!-- Gender Selection -->
      <div class="mt-2 relative">
        <div class="text-sm text-gray-300">Gender</div>
        <div @click="showGenderDropdown=!showGenderDropdown" class="flex justify-between p-2 border border-lighten-40 rounded-xl hover:bg-darken-10">
          <div class="flex text-2xl">
            <s-icon ico="mdi-checkbox-blank-circle" size="24" class="mt-1 mr-2" :color="selectedGender.color"/>
            {{selectedGender.label}}
          </div>
          <s-icon ico="mdi-chevron-down" size="30"/>
        </div>
        <transition name="fade">
          <div
              v-if="showGenderDropdown"
              class="gender-dropdown"
          >
            <div
              v-for="g in genders"
              :key="g.label"
              class="item"
              @click="selectGender(g)"
            >
              {{g.label}}
            </div>
          </div>
        </transition>
      </div>

      <!-- Default Popularity -->
      <div class="mt-2">
        <div class="text-sm text-gray-300">Default Popularity</div>
        <div class="flex justify-center my-2 text-lg">
          {{slider[0]}}
          <s-icon ico="mdi-arrow-left-right" size="28" class="mx-2"/>
          {{slider[1]}}
        </div>
        <div class="border border-lighten-40 rounded-lg p-4">
          <vue-slider
              v-model="slider"
              :min-range="2"
              :tooltip="'none'"
              :adsorb="true"
              :data="popCategories"
              :dot-style="{'background-color': '#ffffff'}"
              :rail-style="{'background-color': 'rgba(0,0,0,0.2)'}"
              :process-style="{'background-color': 'rgba(217,217,217,0.5)'}"
              :step-active-style="{'background-color': 'rgba(5, 150, 105, 0)'}"
              :step-style="{'background-color': 'rgba(0,0,0,0)'}"
          />
          <div class="flex justify-between text-sm">
            <div>Very Popular</div>
            <div>Very Rare</div>
          </div>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="mt-8 flex justify-between">
        <div
          class="action-button"
          v-if="!confirmDelete"
          @click="confirmDelete=true"
          v-ripple
        >
          Delete List
        </div>
        <div
          class="action-button"
          v-if="!confirmDelete"
          v-ripple
        >
          Save
        </div>
        <div
          class="action-button"
          v-if="confirmDelete"
          @click="confirmDelete=false"
          v-ripple
        >
          Back
        </div>
        <div
          class="action-button shadow-lg"
          style="background-color: rgba(255,0,0,0.2)"
          v-if="confirmDelete"
          v-ripple
        >
          Delete List
        </div>

      </div>

    </div>
  </s-card>
</template>

<script>
import SCard from "@/components/core/SCard";
import SIcon from "@/components/core/SIcon";
import {mapMutations} from "vuex";
export default {
  name: "EditListDialog",
  components: {SIcon, SCard},
  data: ()=>({
    popCategories: ['Very Rare', 'Rare', 'Uncommon', 'Common', 'Popular', 'Very Popular'].reverse(),
    slider: ['Very Popular', 'Uncommon'],
    showGenderDropdown: false,
    genders: [
      {label: 'Male', color: '#2980b9'},
      {label: 'Neutral', color: '#8e44ad'},
      {label: 'Female', color: '#D62828'},
      {label: 'All', color: '#27ae60'},
    ],
    selectedGender: {label: 'Male', color: '#2980b9'},
    confirmDelete: false
  }),
  methods: {
    selectGender(gender){
      this.selectedGender = gender;
      this.showGenderDropdown = false;
    },
    ...mapMutations(['toggleShowEditList'])
  }
}
</script>

<style scoped lang="scss">
.form-textbox{
  @apply py-2 px-4 border border-lighten-40 text-xl rounded-lg w-full bg-darken-20;
}
.gender-dropdown{
  @apply absolute right-0 z-10 bg-white text-gray-800 w-full rounded-lg shadow-lg;
  .item{
    @apply p-4 hover:bg-darken-10 cursor-pointer;
  }
}
.action-button{
  @apply bg-darken-20 hover:bg-darken-40 py-2 px-4 rounded-lg cursor-pointer;
}
</style>