<template>
  <div class="s-card shadow-lg rounded-xl">
    <div class="w-full h-full rounded-xl p-4" :class="{'grad-bg': gradient}">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "SCard",
  props: {
    gradient: {default: false}
  }
}
</script>

<style scoped>
  .grad-bg{
    background: rgba(215,222,233, 0);
    background: linear-gradient(225deg, rgba(215,222,233,0) 50%, rgba(0,0,0,0.1) 100%);
  }
</style>