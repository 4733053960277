import fx from "@/assets/functions";
import db from "@/assets/db";

const state = () => ({
  lists: [],
  updates: {}
})

const getters = {
  // Return the currently opened list
  activeList: state => {
    let al = state.lists.find(o => o.active === true);
    return al ? al : {names: [], name: '', defaultPop: {min: 1, max: 6}}
  }
}

const actions = {
  getLists({commit}){
    return new Promise((resolve) => {
      db.getLists().then(resp => {
        // Convert timestamp to Date
        resp.data.forEach(list => {
          list.names = list.names.map(o => {
            o.ts = new Date(o.ts);
            return o;
          });
          fx.categorize(list.names);
          list.modified = new Date(list.modified);
          list.created = new Date(list.created);
        });
        commit('setLists', resp.data);
        resolve(resp.data);
      })
    });

  },
}

const mutations = {
  setLists: (state, lists) => state.lists = lists,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
