import firebase from "firebase/compat";
var provider = new firebase.auth.GoogleAuthProvider();

const state = {

}

const getters = {

}

const actions = {
  // Create a new user with an email and password
  createUserWithEmailAndPassword({commit}, set) {
    firebase.auth().createUserWithEmailAndPassword(set.email, set.password)
      .catch((error) => {
        commit('setError', {name: error.code, msg: error.message});
      });
  },
  // Sign in using email and password
  signInWithEmailAndPassword({commit}, data){
    commit('setLoading', {signIn: true}, {root: true});
    firebase.auth().signInWithEmailAndPassword(data.email, data.password)
      .catch((error)=>{
        console.log('err', error);
      });
  },
  // Sign in using Google
  signInWithGoogle({commit}){
    // commit('setLoading', {signIn: true}, {root: true});
    firebase.auth()
      .signInWithPopup(provider)
      .catch((error) => {
        commit('setError', {name: error.code, msg: error.message});
      });
  },
  // Create an anonymous sign in
  signInAnonymously({commit}) {
    commit('setLoading', {signIn: true}, {root: true});
    firebase.auth().signInAnonymously();
  },
  // Sign out
  signOut(){
    firebase.auth().signOut();
  }
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}