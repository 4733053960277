<template>
  <svg id="Layer_1" class="shadow" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-400 -400 800 800">
    <defs>
      <mask id="innerBoxCut" height="150%" width="150%" x="-25%" y="-25%">
        <circle r="800" fill="white"></circle>
        <rect y="-80" height="160" width="400"></rect>
      </mask>
      <mask id="outerBoxCut" height="150%" width="150%" x="-25%" y="-25%">
        <circle r="800" fill="white"></circle>
        <rect y="-120" height="240" width="600"></rect>
      </mask>
    </defs>
    <title>etns-logo</title>
    <g>
      <circle
          r="200"
          :stroke="color ? color : innerColor"
          stroke-width="100"
          transform="rotate(45)"
          fill="none"
          mask="url(#innerBoxCut)">
        <animate
            attributeType="CSS"
            attributeName="opacity"
            calcMode="spline"
            keySplines="0.5 0 0.5 1 ;"
            keyTimes="0 ; 1"
            values="0 ; 1"
            additive="replace"
            :dur="getDuration()">
        </animate>
        <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            keySplines="0.5 0 0.5 1 ;"
            keyTimes="0 ; 1"
            values="45 ; 405"
            :dur="getDuration()"/>
      </circle>
      <animateTransform
          attributeName="transform"
          type="scale"
          calcMode="spline"
          keySplines="0.5 0 0.5 1 ;"
          keyTimes="0 ; 1"
          values="0.25 ; 1"
          :dur="getDuration()"/>
    </g>
    <g>
      <circle
          r="350"
          :stroke="color ? color : outerColor"
          stroke-width="100"
          transform="rotate(-90)"
          fill="none"
          mask="url(#outerBoxCut)">
        <animate
            attributeType="CSS"
            attributeName="opacity"
            calcMode="spline"
            keySplines="0.5 0 0.5 1 ;"
            keyTimes="0 ; 1"
            values="0 ; 1"
            :dur="getDuration()">
        </animate>
        <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            keySplines="0.5 0 0.5 1 ;"
            keyTimes="0 ; 1"
            values="270 ; -90"
            :dur="getDuration(1.25)"/>
      </circle>
      <animateTransform
          attributeName="transform"
          type="scale"
          calcMode="spline"
          keySplines="0.5 0 0.5 1 ;"
          keyTimes="0 ; 1"
          values="0 ; 1"
          :dur="getDuration()"/>
    </g>

  </svg>
</template>

<script>
export default {
  name: "etnsLogoIcon",
  props: {
    innerColor: {default: '#ccc'},
    outerColor: {default: '#ccc'},
    color: {default: null},
    duration: {default: 1},
  },
  methods: {
    getDuration(scale=1){
      return this.duration * scale + 's';
    },
  }
}
</script>

<style scoped>
.circle-inner {
  animation-name: star;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: center center;
  transform-box: fill-box;
}

@keyframes star {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0);
  }
}

.shadow {
  -webkit-filter: drop-shadow( -10px 10px 20px rgba(0, 0, 0, .5));
  filter: drop-shadow( -10px 10px 20px rgba(0, 0, 0, .5));
  /* Similar syntax to box-shadow */
}
</style>