<template>
  <div class="splash-screen relative">
    <div class="flex justify-center">
      <etns-logo-icon class="w-40 h-40 mt-6 md:mt-0" inner-color="white" outer-color="white"/>
      <vue-aos animation-class="fadeIn animated">
        <div class="title">
          <div class="hidden md:inline">End The<br>Name Search</div>
          <div class="md:hidden max-w-min">End The Name Search</div>
        </div>
      </vue-aos>
    </div>

    <div class="subheading">
      Find the perfect name
    </div>
  </div>
</template>

<script>
import EtnsLogoIcon from "@/assets/etnsLogoIcon";
export default {
name: "SplashScreen",
  components: {EtnsLogoIcon},
  methods: {
    toggleDark(){
      this.$store.commit('toggleDark');
    }
  }
}
</script>

<style scoped lang="scss">

.splash-screen{
  @apply h-80 md:h-96 md:pt-12;
  margin-top: -64px;

  .title{
    @apply text-5xl md:text-7xl pt-2 ml-6 md:ml-12 font-bold text-shadow-lg text-white;
    font-family: 'Quicksand', Avenir, sans-serif;
  }

  .subheading{
    @apply absolute bottom-0 w-full text-center text-3xl text-white mt-6 bg-darken-20 p-6;
  }

}

// Fix for border around logo
.shadow{--tw-shadow: none;}
.text-shadow-lg {text-shadow: 0 10px 25px rgba(0,0,0,0.6);}
</style>