<template>
  <div id="app" :class="{'dark': darkMode}">
    <s-top-nav class="magenta-svg"/>
    <div :class="[$route.name === 'Home' ? 'mt-16':'mt-8']"></div>
    <router-view/>

    <s-dialog :show="showSignIn" v-on:close="closeSignIn">
      <sign-in-dialog/>
    </s-dialog>
    <s-dialog :show="showEditList" v-on:close="closeEditList">
      <edit-list-dialog/>
    </s-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import STopNav from "@/components/core/STopNav/STopNav";
import SDialog from "@/components/core/SDialog";
import SignInDialog from "@/components/SignInDialog";
import EditListDialog from "@/components/EditListDialog";

export default {
  components: {EditListDialog, SignInDialog, SDialog, STopNav},
  computed: {
    ...mapState(['darkMode', 'showSignIn', 'showEditList']),
    screenSize(){
      return this.$screen.breakpoint;
    }
  },
  methods: {
    closeSignIn(){
      this.$store.commit('toggleShowSignIn', false);
    },
    closeEditList(){
      this.$store.commit('toggleShowEditList', false);
    }
  },
  watch: {
    screenSize(val){console.log(val)},
  },
  mounted() {
    this.$store.dispatch('init');
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Quicksand', Avenir, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

// Gradients
.magenta-svg{
  background-color: #330000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23D18'/%3E%3Cstop offset='1' stop-color='%23330000'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23FA3' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23FA3' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.4'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.male-gradient{@apply bg-gradient-to-tr from-male-dark to-male-light;  }
.neutral-gradient{@apply bg-gradient-to-tr from-neutral-dark to-neutral-light;  }
.female-gradient{@apply bg-gradient-to-tr from-female-dark to-female-light;  }
.all-gradient{@apply bg-gradient-to-tr from-all-dark to-all-light;  }

.fade-enter-active, .fade-leave-active {transition: opacity .25s;}
.fade-enter, .fade-leave-to {opacity: 0;}

.slide-leave-active,
.slide-enter-active {transition: 0.35s;}
.slide-enter {transform: translate(100%, 0);}
.slide-leave-to {transform: translate(-100%, 0);}

/* Scrollbar */
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}
*::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.2);
  border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 4px;
}
</style>