import firebase from "firebase/compat";

let db = function(){
  this.init = function(){
    let actions = {
      // Names
      searchNames:        firebase.app().functions().httpsCallable('searchNames'),
      getName:            firebase.app().functions().httpsCallable('getName'),
      // Lists
      getLists:           firebase.app().functions().httpsCallable('getLists'),
      openList:           firebase.app().functions().httpsCallable('openList'),
      modifyList:         firebase.app().functions().httpsCallable('modifyList'),
      createList:         firebase.app().functions().httpsCallable('createList'),
      deleteList:         firebase.app().functions().httpsCallable('deleteList'),
      addNames:           firebase.app().functions().httpsCallable('addNames'),
      updateListNames:    firebase.app().functions().httpsCallable('updateListNames'),
      // User
      getUser:            firebase.app().functions().httpsCallable('getUser'),
    }
    Object.keys(actions).forEach(action => {
      this[action] = actions[action];
    })
  }
}

export default new db();