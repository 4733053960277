import Vue from 'vue'
import Vuex from 'vuex'
import db from '../assets/db.js';
import firebase from 'firebase/compat';
import auth from "@/store/auth";
import lists from "@/store/lists";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    darkMode: false,
    signedIn: false,
    // Dialogs
    showSignIn: false,
    showEditList: false
  },
  mutations: {
    // Toggle dark mode
    toggleDark(state){state.darkMode = !state.darkMode;},
    // Toggle sign-in dialog
    toggleShowSignIn(state, setTo=null){
      if(setTo === false || setTo === true) return state.showSignIn = setTo;
      state.showSignIn = !state.showSignIn
    },
    // Toggle edit list dialog
    toggleShowEditList(state, setTo=null){
      if(setTo === false || setTo === true) return state.showEditList = setTo;
      state.showEditList = !state.showEditList
    },
    // Set the state
    setState(state, opt){
      Object.keys(opt).forEach(key => {
        if(state[key] !== undefined) state[key] = opt[key];
      })
    }
  },
  actions: {
    /*
    Initialize the DB functions and set up auth state changes
     */
    init: function({commit, dispatch}){
      db.init();
      firebase.auth().onAuthStateChanged(function(user) {
        if(user){
          commit('setState', {signedIn: !user.isAnonymous, showSignIn: false});
          dispatch('lists/getLists', {}, {root: true}).then((lists)=>{
            console.log(lists);
          });
          console.log(user);
          console.log(user.uid);
        }else{
          commit('setState', {signedIn: false})
          console.log('No user')
        }
      })
    }
  },
  modules: {
    auth,
    lists
  }
})
