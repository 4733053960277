let popCategories = [
  {rng: [0,     0.005],   label: 'Unique',        color: '#116666'},
  {rng: [0.005, 0.010],   label: 'Very Rare',     color: '#FF2266'},
  {rng: [0.010, 0.100],   label: 'Rare',          color: '#FF6611'},
  {rng: [0.100, 0.250],   label: 'Uncommon',      color: '#662211'},
  {rng: [0.250, 1.000],   label: 'Common',        color: '#1166EE'},
  {rng: [1.000, 3.000],   label: 'Popular',       color: '#5522bb'},
  {rng: [3.000, 10000],   label: 'Very Popular',  color: '#88EE11'},
];

let genCategories = [
  {rng: [0.000, 0.300],   label: 'Female',        color: '#FF6666'},
  {rng: [0.300, 0.700],   label: 'Neutral',       color: '#FF6666'},
  {rng: [0.700, 2.000],   label: 'Male',          color: '#FF6666'},
  {rng: [0, 2.000],       label: 'All',           color: '#FF6666'},
];

let popIndicatorPercents = [
  [0,0],
  [0.1, 0.1],
  [0.5, 0.2],
  [10, 1]
];

let getPopDisplayPercentage = function(pop){
  let i = popIndicatorPercents.findIndex(o => pop < o[0]);
  return i;
}

export default {
  popCategories,
  genCategories,
  setPopCategories: function(names){
    let singleValue = false;
    if(names.names) names = names.names;    // If a list is given
    if(!Array.isArray(names)) {
      names = [names];
      singleValue = true;
    }

    names = names.map(name => {
      let pop = name.computed.retro5.npk;
      name.popCategory = popCategories.find(o => pop >= o.rng[0] && pop < o.rng[1])
      name.popCategory.p = getPopDisplayPercentage(pop);
    });
    return singleValue ? names[0] : names;
  },
  setGenCategories: function(names){
    let singleValue = false;
    if(names.names) names = names.names;    // If a list is given
    if(!Array.isArray(names)) {
      names = [names];
      singleValue = true;
    }

    names = names.map(name => {
      let pop = name.computed.retro5.mfr;
      name.genCategory = genCategories.find(o => pop >= o.rng[0] && pop < o.rng[1])
    });
    return singleValue ? names[0] : names;
  },
  categorize: function(names){
    this.setPopCategories(names);
    this.setGenCategories(names);
    return names;
  },
  getPopRange: function(rng=['Very Popular', 'Common']){
    return [
      popCategories.find(o => o.label === rng[1]).rng[0],
      popCategories.find(o => o.label === rng[0]).rng[1],
    ]
  },
  getNeutralityRange: function(label){
    let n = genCategories.find(o => o.label === label);
    if(n) return n.rng;
    return [0, 1];
  },
  /**
   * Color functions
   */
  positiveSpace(theme={}, dark=false){    // eslint-disable-line no-unused-vars
    return dark ? 'rgba(20,20,30,1)' : 'rgba(230,230,230,1)'
  },
  /**
   * Other functions
   */
  pick: function(obj, fields){
    let o = {};
    fields.forEach(field => {o[field] = obj[field]});
    return o;
  }
}